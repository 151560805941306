import React from "react";

import { Button } from "@mui/material";

import { createEmptyAccount } from "./AddAccount.functions";
import EditAccount from "./EditAccount";
import { useServer } from "../Server/ServerContext";

import { Account } from "../types";

function AddAccount() {
  const { createAccount } = useServer();

  const [account, setAccount] = React.useState<Account | null>(null);

  return (
    <>
      <Button
        variant="contained"
        sx={{ margin: "1rem 0.5rem" }}
        onClick={() => setAccount(createEmptyAccount())}
      >
        Account erstellen
      </Button>

      <EditAccount
        account={account}
        updateAccount={async (account) => {
          await createAccount(account as Account);
          setAccount(null);
        }}
        onClose={() => setAccount(null)}
        isNew
      />
    </>
  );
}

export default AddAccount;
