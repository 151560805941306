import React from "react";

import {
  decodeJWT,
  getNamespaceInfo,
  loadAccounts,
  login,
  resetNamespace as resetNamespaceFct,
} from "./ServerContext.functions";

import { Account } from "../types";
import { jwt, setJwt } from "./jwt";
import Server from "./GenericServer";
import config from "../config";

export interface ServerHook {
  accounts: Account[];
  user: string | null;
  loggedIn: boolean;
  namespace: number;
  adminEmail: string | null;

  resetNamespaceMode: boolean;

  logout: () => Promise<void>;
  login: (username: string, password: string) => Promise<string>;

  updateAccount: (account: Account, oldUsername?: string) => Promise<void>;
  deleteAccount: (account: Account) => Promise<void>;
  createAccount: (account: Account) => Promise<void>;

  resetNamespace: (
    account: Pick<Account, "displayname" | "username"> & { password: string }
  ) => Promise<string>;
}

const ServerContext = React.createContext<ServerHook>(undefined!);

export function useServer() {
  return React.useContext(ServerContext);
}

export function ServerProvider(props: { children: JSX.Element }) {
  const [accounts, setAccounts] = React.useState<Account[]>([]);
  const [user, setUser] = React.useState<string | null>(null);
  const [resetToken, setResetToken] = React.useState<string | null>(null);
  const resetNamespaceMode = React.useMemo(
    () => resetToken !== null,
    [resetToken]
  );

  const [adminEmail, setAdminEmail] = React.useState<string | null>(null);

  const namespace = jwt ? decodeJWT(jwt)?.namespace || -1 : -1;

  // load accounts and admin email
  React.useEffect(
    () => {
      if (user && jwt) {
        loadAccounts().then(setAccounts);
        getNamespaceInfo().then((info) => {
          try {
            setAdminEmail(info.email);
          } catch (e) {
            console.error(e);
          }
        });
      }
    },
    //eslint-disable-next-line
    [user, jwt]
  );

  // get reset_token from url search params
  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const reset_token = searchParams.get("token");
    setResetToken(reset_token);
  }, []);

  const exportValue = React.useMemo(
    () => ({
      accounts,
      user,
      loggedIn: user !== null,
      namespace,
      adminEmail,

      resetNamespaceMode,

      logout: async () => {
        setJwt("");
        setAccounts([]);
        setUser(null);
      },
      login: async (username: string, password: string) => {
        const result = await login(username, password);

        if (result.error !== undefined) {
          return result.error;
        }
        setJwt(result.jwt as string);
        setUser(username);
        return "";
      },

      updateAccount: async (account: Account, oldUsername?: string) => {
        const index = accounts.findIndex(
          (a) => a.username === (oldUsername || account.username)
        );

        if (index === -1) return;

        const newAccounts = [...accounts];
        newAccounts[index] = account;

        setAccounts(newAccounts);

        await Server.patch("user/:username", {
          root: config.loginServer,
          body: {
            ...account,
          },
          params: {
            username: oldUsername || account.username,
          },
        });
      },
      deleteAccount: async (account: Account) => {
        const index = accounts.findIndex(
          (a) => a.username === account.username
        );

        if (index === -1) return;

        const newAccounts = [...accounts];
        newAccounts.splice(index, 1);

        setAccounts(newAccounts);

        await Server.delete("user/:username", {
          root: config.loginServer,
          params: {
            username: account.username,
          },
        });
      },
      createAccount: async (account: Account) => {
        const newAccounts = [...accounts, account];

        setAccounts(newAccounts);

        await Server.post("user", {
          root: config.loginServer,
          body: {
            ...account,
          },
        });
      },
      resetNamespace: async (
        account: Pick<Account, "displayname" | "username"> & {
          password: string;
        }
      ) => {
        if (!resetToken) return "not in reset mode";
        return await resetNamespaceFct(resetToken, account);
      },
    }),
    [accounts, user, namespace, resetNamespaceMode, resetToken, adminEmail]
  );

  return (
    <ServerContext.Provider value={exportValue}>
      {props.children}
    </ServerContext.Provider>
  );
}
