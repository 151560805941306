const config = {
  editAboLink: "",
  serverRoot: window.location.origin + "/api",
  loginServer: "https://app.emmasy.com/api/login/",
  mainServer: "https://app.emmasy.com/api/main/",
  customerFrontend: "https://customer.emmasy.com?ns={{namespace}}",
  employeeFrontend: "https://app.emmasy.com",

  passwordSecurity: {
    minLength: 8,
    minSpecial: 1,
    minNumber: 0,
    minUpper: 0,
  },

  contact: {
    email: "support@idot-digital.de",
    phone: "+49 241 56003009",
  },

  devLogin: {
    username: undefined,
    password: undefined,
  },
};

export default config;
