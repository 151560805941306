import { Objects } from "@idot-digital/generic-helpers";
import config from "../config";

import { Account } from "../types";
import { EditAccountType } from "./EditAccount";

/**
 * Check if account is valid
 * @param account Account to check
 * @returns True if account is valid
 */
export function accountValid(
  account: EditAccountType,
  isNew: boolean
): boolean {
  // Check basic inputs
  if (!account.displayname) return false;
  if (!account.username) return false;
  if (!account.password && isNew) return false;

  return true;
}

/**
 * Convert EditAccountType to Account
 * @param account Account to convert
 * @returns Converted account
 */
export function convertToAccount(account: EditAccountType): Account {
  const converted = Objects.deepClone(account) as Omit<
    EditAccountType,
    "password"
  > &
    Partial<Pick<EditAccountType, "password">>;
  delete converted.password;

  // Delete and pin
  if (converted.permission === "store_account") {
    converted.pin = null;
  }

  // Convert permissions
  converted.permissions = converted.permissions.filter(
    (permission) => permission !== "admin" && permission !== "store_account"
  );

  if (
    converted.permission === "admin" ||
    converted.permission === "store_account"
  )
    converted.permissions.push(converted.permission);

  return converted;
}

/**
 * Convert Account to EditAccountType
 * @param account Account to convert
 * @returns Converted account
 */
export function convertToEditAccount(account: Account): EditAccountType {
  const converted: EditAccountType = {
    permission: "employee",
    password: "",
    ...Objects.deepClone(account),
  };

  // Apply current permission
  if (converted.permissions.includes("admin")) converted.permission = "admin";
  else if (converted.permissions.includes("store_account"))
    converted.permission = "store_account";

  return converted;
}

export function isPasswordSecure(password: string): boolean {
  // Check length
  if (password.length < config.passwordSecurity.minLength) return false;

  let numbers = 0;
  let upper = 0;
  let special = 0;
  for (let i = 0; i < password.length; i++) {
    if (!isNaN(parseInt(password[i]))) numbers++;
    if (password[i] === password[i].toUpperCase()) upper++;
    if (
      isNaN(parseInt(password[i])) &&
      password[i] === password[i].toUpperCase() &&
      password[i] === password[i].toLowerCase()
    )
      special++;
  }
  if (numbers < config.passwordSecurity.minNumber) return false;
  if (upper < config.passwordSecurity.minUpper) return false;
  if (special < config.passwordSecurity.minSpecial) return false;

  return true;
}
