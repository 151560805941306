import React from "react";

import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  accountValid,
  convertToAccount,
  convertToEditAccount,
  isPasswordSecure,
} from "./EditAccount.functions";

import { Account } from "../types";
import { Info } from "@mui/icons-material";
import config from "../config";

export type EditAccountProps = {
  account: Account | null;
  updateAccount?: (account: Account, oldUsername?: string) => Promise<void>;
  onClose?: () => void;
} & (
  | {
      isNew: true;
      adminAmount?: number;
    }
  | { adminAmount: number; isNew?: boolean }
);

export type EditAccountType = Account & {
  permission: "employee" | "store_account" | "admin";
  pin: string | null;
  password: string;
};

function EditAccount(props: EditAccountProps) {
  const [account, setAccount] = React.useState<EditAccountType | null>(null);
  const [firstPasswordFocus, setFirstPasswordFocus] = React.useState(
    !props.isNew
  );
  const [loading, setLoading] = React.useState(false);

  const passwordSecure = React.useMemo(() => {
    // if password is not changed, it is secure
    if (firstPasswordFocus) return true;
    return isPasswordSecure(account?.password || "");
  }, [account, firstPasswordFocus]);

  const updateValues = (values: Partial<EditAccountType>) => {
    setAccount((account) => (account ? { ...account, ...values } : account));
  };

  React.useEffect(() => {
    if (props.account) setAccount(convertToEditAccount(props.account));
  }, [props.account]);

  const isLastAdmin = React.useMemo(
    () =>
      !props.isNew && props.adminAmount <= 1 && account?.permission === "admin",
    [props.isNew, account, props.adminAmount]
  );

  const ButtonGroupWrapper = isLastAdmin ? Tooltip : React.Fragment;
  const ButtonGroupProps = isLastAdmin
    ? {
        title:
          "Dies ist der letzte Admin und kann somit nicht verändert werden",
      }
    : {};

  return account ? (
    <Dialog open={!!props.account} fullWidth maxWidth="xs">
      <DialogTitle>Accountverwaltung</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={account.displayname}
          onChange={(event) =>
            updateValues({ displayname: event.target.value })
          }
          margin="normal"
          fullWidth
        />
        <TextField
          label="Username"
          value={account.username}
          onChange={(event) => updateValues({ username: event.target.value })}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          autoComplete="new-password"
          value={firstPasswordFocus ? "*******" : account.password}
          onChange={(event) => updateValues({ password: event.target.value })}
          margin="normal"
          fullWidth
          onFocus={() => setFirstPasswordFocus(false)}
          error={!passwordSecure}
          slotProps={{
            input: {
              endAdornment: passwordSecure ? null : (
                <Tooltip
                  title={
                    <Typography variant="caption">
                      Das Password ist nicht sicher genug. Es muss mindestens
                      {[
                        config.passwordSecurity.minLength ? (
                          <React.Fragment key={"length"}>
                            <br />
                            {config.passwordSecurity.minLength} Zeichen lang
                            sein
                          </React.Fragment>
                        ) : null,
                        config.passwordSecurity.minNumber ? (
                          <React.Fragment key={"number"}>
                            <br />
                            {config.passwordSecurity.minNumber} Zahlen enthalten
                          </React.Fragment>
                        ) : null,
                        config.passwordSecurity.minSpecial ? (
                          <React.Fragment key={"special"}>
                            <br />
                            {config.passwordSecurity.minSpecial} Sonderzeichen
                            enthalten
                          </React.Fragment>
                        ) : null,
                        config.passwordSecurity.minUpper ? (
                          <React.Fragment key={"upper"}>
                            <br />
                            {config.passwordSecurity.minUpper} Großbuchstaben
                            enthalten
                          </React.Fragment>
                        ) : null,
                      ]}
                    </Typography>
                  }
                >
                  <Info fontSize="small" color="error" />
                </Tooltip>
              ),
            },
          }}
        />
        {account.permission === "employee" ? (
          <TextField
            label="Pin"
            type="password"
            value={account.pin || ""}
            onChange={(event) => updateValues({ pin: event.target.value })}
            margin="normal"
            autoComplete="new-password"
            fullWidth
          />
        ) : null}
        {/* @ts-ignore */}
        <ButtonGroupWrapper {...ButtonGroupProps}>
          <ButtonGroup
            color="inherit"
            variant="contained"
            fullWidth
            sx={{ marginTop: "1rem" }}
            // disable permissions switching for the last admin account (this must also be existing account)
            disabled={isLastAdmin}
          >
            <Button
              color={account.permission === "employee" ? "primary" : "inherit"}
              onClick={() => updateValues({ permission: "employee" })}
            >
              Mitarbeiteraccount
            </Button>
            <Button
              color={
                account.permission === "store_account" ? "primary" : "inherit"
              }
              onClick={() => updateValues({ permission: "store_account" })}
            >
              Ladenaccount
            </Button>
            <Button
              color={account.permission === "admin" ? "primary" : "inherit"}
              onClick={() => updateValues({ permission: "admin" })}
            >
              Admin
            </Button>
          </ButtonGroup>
        </ButtonGroupWrapper>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={props.onClose}>
          Abbrechen
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={async () => {
            setLoading(true);
            const converted = convertToAccount(account);
            if (account.password) {
              //@ts-ignore
              converted.password = account.password;
            }
            if (converted)
              await props.updateAccount?.(converted, props.account?.username);
            setLoading(false);
          }}
          disabled={
            !accountValid(account, props.isNew || false) || !passwordSecure
          }
          loading={loading}
        >
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  ) : null;
}

export default EditAccount;
